@import-normalize;

:root {
  --breakpoint-0: 576px;
  --breakpoint-1: 768px;
  --breakpoint-2: 992px;
  --breakpoint-3: 1200px;

  --fontsize-0: 12px;
  --fontsize-1: 14px;
  --fontsize-2: 18px;
  --fontsize-3: 20px;
  --fontsize-4: 24px;
  --fontsize-5: 32px;
  --fontsize-6: 48px;
  --fontsize-7: 64px;

  --color-primary-0: #f7766c;
  --color-primary-1: #ff5656;
  --color-primary-2: #ff2020;
  --color-secondary-0: #428bca;
  --color-secondary-1: #428bca;
  --color-secondary-2: #428bca;
  --color-accent-0: #5a1564;
  --color-accent-1: #9c27b0;
  --color-accent-2: #721d81;
  --color-success-0: #7cc47f;
  --color-success-1: #4caf50;
  --color-success-2: #38803b;
  --color-warning-0: #ffb445;
  --color-warning-1: #ff9800;
  --color-warning-2: #ba6f00;
  --color-error-0: #f7766c;
  --color-error-1: #f44336;
  --color-error-2: #b23128;
  --color-light-0: #fff;
  --color-light-1: #f0f0f0;
  --color-light-2: #efefef;
  --color-light-3: #d0d0d0;
  --color-dark-0: #8c8c8c;
  --color-dark-1: #666;
  --color-dark-2: #444;
  --color-dark-3: #000;

  --space-0: 0px;
  --space-1: 4px;
  --space-2: 5px;
  --space-3: 16px;
  --space-4: 32px;
  --space-5: 64px;
  --space-6: 128px;
  --space-7: 256px;

  --content-width: 1180px;
  --sidebar-width: 300px;
  --navbar-height: 100px;
}

html,
body,
#root {
  height: calc(100vh - var(--navbar-height));
  margin: 0;
  padding: 0;
  font-family: Helvetica, 'LiHei Pro', '微軟正黑體', sans-serif;
  color: var(--color-dark-1);
  background-color: #fdfdfd;
}

p {
  line-height: 1.5;
}

@media screen and (max-width: 576px) {
  :root {
    --navbar-height: 56px;
    --content-width: 100%;
  }
}
