.link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--color-secondary-1);
  cursor: pointer;
}

.link:hover {
  color: var(--color-secondary-0);
}

.link__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--fontsize-3);
}

.link__icon--left {
  margin-right: var(--space-2);
}

.link__icon--right {
  margin-left: auto;
}

.link__text {
  flex: 1;
}
