.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch__checkbox {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.switch__indicator {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 34px;
}

.switch__indicator:focus {
  box-shadow: 0 0 1px #333;
}

.switch__indicator:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  border-radius: '50%';
  background-color: '#fff';
  transition: '0.2s';
}
