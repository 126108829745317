.sidebar__overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: var(--color-dark-3);
  transform: scale(0);
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.sidebar__wrap {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  z-index: 1001;
  background-color: var(--color-light-0);
  overflow-x: hidden;
  box-shadow: 2px 0 4px rgba(0, 0, 0, 0.25);
  transition: 0.3s;
}

.sidebar {
  position: absolute;
  top: 0;
  left: 0;
  width: var(--sidebar-width);
  z-index: 3;
}

.sidebar__logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--navbar-height);
  padding: 0 var(--space-3);
  color: var(--color-light-0);
  background-color: var(--color-secondary-1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.125);
}

.sidebar__logo img {
  height: 40px;
}

.sidebar__logo h1 {
  margin: 0;
  font-size: var(--fontsize-4);
}

.sidebar button {
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--color-light-0);
}

.sidebar__link {
  padding: var(--space-2) var(--space-3);
  color: var(--color-dark-2);
}

.sidebar__link:hover {
  color: var(--color-dark-1);
}

.sidebar__link--active,
.sidebar__link--active:hover {
  color: var(--color-dark-1);
  text-decoration: underline;
}

.sidebar__group {
  padding: var(--space-2) var(--space-3);
  margin: var(--space-3) 0 var(--space-2);
  color: var(--color-dark-0);
  background-color: var(--color-light-2);
  border-top: 1px solid var(--color-light-3);
}

.sidebar__group__content {
  margin-bottom: var(--space-3);
}
