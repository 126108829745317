.modal__overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
  background-color: var(--color-dark-3);
  transition: opacity 0.3s ease-out;
}

.modal__wrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2001;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

.modal {
  background-color: var(--color-light-2);
  border-radius: 3px;
  overflow-y: auto;
}

.modal__content {
  padding: var(--space-3);
}

.modal__header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--space-3);
  background-color: var(--color-light-0);
  font-size: var(--fontsize-3);
}

.modal__header button {
  padding: 0;
  color: var(--color-dark-2);
  background-color: var(--color-light-0);
  border: none;
  outline: none;
  cursor: pointer;
}

.modal__header button:hover {
  color: var(--color-secondary-1);
}

.modal__header button:disabled {
  color: var(--color-dark-0);
  cursor: not-allowed;
}

.modal__footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--space-3);
  background-color: var(--color-light-0);
  gap: var(--space-3);
}

.modal__footer button {
  display: flex;
  justify-content: center;
  width: 160px;
  padding: calc(var(--space-2) + var(--space-1));
  border: 1px solid var(--color-primary-1);
  border-radius: 3px;
  font-size: var(--fontsize-1);
  text-align: center;
  transition: background-color 0.2s ease-in;
}

.modal__footer__cancel {
  color: var(--color-primary-1);
  background-color: var(--color-light-0);
}

.modal__footer__cancel:hover {
  color: var(--color-primary-2);
}

.modal__footer__submit {
  color: var(--color-light-0);
  background-color: var(--color-primary-1);
}

.modal__footer__submit:hover {
  background-color: var(--color-primary-2);
}

.modal__footer__cancel:disabled,
.modal__footer__submit:disabled {
  color: var(--color-dark-0);
  border-color: var(--color-light-3);
  background-color: var(--color-light-0);
  cursor: not-allowed;
}

@media screen and (max-width: 576px) {
  .modal {
    width: 100%;
  }
}
