.table {
  padding: var(--space-2);
  width: 100%;
  color: var(--color-dark-2);
  border-collapse: collapse;
}

.table__row {
  border-bottom: none;
  font-weight: 300;
  white-space: nowrap;
}

.table__row:nth-child(odd) {
  background-color: var(--color-light-1);
}

.table__row button {
  padding: 0;
  color: var(--color-dark-1);
  background-color: var(--color-light-0);
  border: none;
  outline: none;
  cursor: pointer;
}

.table__row button:hover {
  color: var(--color-secondary-1);
}

.table__head-cell {
  padding: var(--space-3) var(--space-2);
  border-bottom: 1px solid var(--color-light-3);
  font-weight: 300;
  white-space: nowrap;
}

.table__cell {
  padding: var(--space-2);
  color: var(--color-dark-1);
  border-bottom: 1px solid var(--color-light-3);
}

.table__empty-row {
  background-color: var(--color-light-1);
}

.table__empty-row td {
  padding: var(--space-3);
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--color-light-3);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--color-light-3);
}

.table__empty-row td > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: var(--color-light-1);
  color: var(--color-light-3);
}

.table__pagination {
  padding: var(--space-3) var(--space-2) 0;
  border-top: 1px solid var(--color-light-3);
}
