.bar {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
  transition: margin-left 0.3s;
  animation: slide 0.2s linear;
}

.bar__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 var(--space-4);
  padding: var(--space-3) var(--space-3);
  background-color: var(--color-light-0);
  gap: var(--space-3);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.125);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.bar__content button {
  padding: var(--space-2) var(--space-3);
  font-size: var(--fontsize-1);
  border-radius: 3px;
  outline: none;
  cursor: pointer;
}

.bar__content button:active {
  position: relative;
  top: 1px;
}

.bar__content button:disabled {
  color: var(--color-dark-0);
  border-color: var(--color-light-3);
  background-color: var(--color-light-0);
  background-image: none;
  box-shadow: 2px 3px 0px 0px var(--color-light-3);
  cursor: not-allowed;
}

.bar__content button[type='button'] {
  color: var(--color-dark-2);
  background-color: var(--color-light-0);
  box-shadow: 2px 3px 0px 0px var(--color-dark-1);
  border: 1px solid var(--color-dark-1);
}

.bar__content button[type='button']:hover {
  color: var(--color-secondary-2);
  border-color: var(--color-secondary-1);
  box-shadow: 2px 3px 0px 0px var(--color-secondary-1);
}

.bar__content button[type='submit'] {
  color: var(--color-accent-1);
  background-color: var(--color-light-0);
  box-shadow: 2px 3px 0px 0px var(--color-accent-1);
  border: 1px solid var(--color-accent-1);
}

.bar__content button[type='submit']:hover {
  color: var(--color-light-0);
  background: linear-gradient(
    60deg,
    var(--color-accent-0),
    var(--color-accent-1)
  );
  box-shadow: 2px 3px 0px 0px var(--color-accent-2);
}

@keyframes slide {
  from {
    transform: translate(0, 32px);
  }
  to {
    transform: translate(0);
  }
}
