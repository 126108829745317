.main {
  margin-top: var(--navbar-height);
  min-height: calc(100vh - 417px);
}

.footer {
  border-top: 1px solid var(--color-light-3);
}

.footer__content {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  width: var(--content-width);
  margin: 0 auto;
  padding: var(--space-5) 0;
}

.footer__section {
  display: flex;
  flex-direction: column;
  gap: var(--space-3);
}

.footer__section__title {
  font-size: var(--fontsize-2);
  font-weight: 500;
}

.footer__section__link {
  color: var(--color-dark-0);
}

.footer__section__link:hover {
  color: var(--color-dark-0);
  text-decoration: underline;
}

.footer__copyright {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: var(--content-width);
  margin: 0 auto;
  padding-bottom: var(--space-3);
  color: var(--color-dark-0);
  font-size: var(--fontsize-0);
}

@media screen and (max-width: 576px) {
  .main {
    min-height: calc(100vh - 188px);
  }

  .footer__content {
    padding: var(--space-4) 0;
    font-size: var(--fontsize-1);
  }

  .footer__copyright {
    flex-direction: column;
    align-items: center;
    gap: var(--space-2);
  }
}
