.text-input {
  width: 100%;
  min-height: 38px;
  padding: var(--space-2) var(--space-3);
  margin: 0;
  font-size: var(--fontsize-1);
  color: var(--color-dark-2);
  box-sizing: border-box;
  border: 1px solid var(--color-light-3);
  border-radius: 4px;
  transition: border-color 0.2s linear;
  will-change: border-color;
}

.text-input--error,
.text-input--error:focus {
  border-color: var(--color-error-1);
  font-size: var(--fontsize-1);
}

.text-input:focus {
  outline: none;
  border-color: var(--color-accent-1);
}
