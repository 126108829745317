.sidebar__wrap {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  z-index: 90;
  background-color: var(--color-light-0);
  overflow-x: hidden;
  box-shadow: 2px 0 4px rgba(0, 0, 0, 0.15);
  transition: 0.3s;
}

.sidebar {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  margin-top: var(--navbar-height);
  padding: var(--space-3) 0;
}

.sidebar__link {
  padding: var(--space-2) var(--space-3);
  color: var(--color-dark-2);
}

.sidebar__link:hover {
  color: var(--color-dark-1);
}

.sidebar__link--active,
.sidebar__link--active:hover {
  color: var(--color-dark-1);
  text-decoration: underline;
}
