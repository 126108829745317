.container {
  max-width: var(--content-width);
  min-height: 100%;
  margin: 0 auto;
}

.breadcrumb {
  padding: var(--space-3) 0;
}

.breadcrumb__link {
  color: var(--color-dark-1);
}

.breadcrumb__link:hover {
  color: var(--color-dark-1);
  text-decoration: underline;
}

.content {
  display: flex;
  justify-content: space-between;
}

.sidebar {
  width: 265px;
}

.sidebar__link {
  padding: var(--space-2) 0;
  color: var(--color-dark-1);
  font-size: var(--fontsize-1);
}

.sidebar__link:hover {
  color: var(--color-dark-1);
  background-color: var(--color-light-1);
}

.sidebar__link--active {
  text-decoration: underline;
}

.main {
  width: 880px;
  box-sizing: border-box;
  margin-bottom: var(--space-4);
}

@media screen and (max-width: 576px) {
  .main {
    width: 100%;
    padding: var(--space-3);
  }
}
