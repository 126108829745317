.breadcrumb {
  display: flex;
  list-style: 'none';
  color: var(--color-light-0);
  padding: 0;
  margin: 0;
}

.breadcrumb__item {
  display: flex;
  align-items: center;
  color: var(--color-dark-1);
}

.breadcrumb__item + .breadcrumb__item::before {
  content: '/';
  padding: 0 var(--space-3);
}

.breadcrumb__item--inactive {
  color: var(--color-dark-0);
}

.breadcrumb__link {
  color: var(--color-dark-1);
  font-size: var(--fontsize-1);
}

.breadcrumb__link:hover {
  color: var(--color-dark-1);
  text-decoration: underline;
}

.breadcrumb__text {
  color: var(--color-dark-0);
  font-size: var(--fontsize-1);
}
