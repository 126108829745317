.container {
  max-width: var(--content-width);
  min-height: 100%;
  margin: 0 auto;
}

.breadcrumb {
  padding: var(--space-3) 0;
}

.breadcrumb__link {
  color: var(--color-dark-1);
}

.breadcrumb__link:hover {
  color: var(--color-dark-1);
  text-decoration: underline;
}

.content {
  display: flex;
  justify-content: space-between;
}

.sidebar {
  width: 265px;
}

.sidebar__title {
  font-size: var(--fontsize-2);
  font-weight: bold;
  margin: 0 0 var(--space-2);
}

.sidebar__section {
  padding: 0 0 var(--space-3);
  border-bottom: 1px solid var(--color-light-3);
}

.sidebar__link {
  padding: var(--space-2) 0;
  color: var(--color-dark-1);
  font-size: var(--fontsize-1);
}

.sidebar__link:hover {
  color: var(--color-dark-1);
  background-color: var(--color-light-1);
}

.sidebar__link--active {
  text-decoration: underline;
}

.product {
  width: 214px;
  text-decoration: none;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.product:hover {
  transform: scale(1.02);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
}

.product__image {
  width: 214px;
  height: 214px;
}

.product__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product__info {
  padding: var(--space-3);
}

.product__name {
  color: var(--color-dark-1);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  box-sizing: border-box;
}

.product__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--space-3);
}

.product__price {
  color: var(--color-error-1);
}

.main {
  width: 880px;
  box-sizing: border-box;
  margin-bottom: var(--space-4);
}

@media screen and (max-width: 576px) {
  .main {
    width: 100%;
  }
}