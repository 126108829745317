.wrapper {
  max-width: var(--content-width);
  min-height: 100%;
  margin: 0 auto var(--space-4);
  padding-top: var(--space-4);
}

.container {
  width: 600px;
  margin: var(--space-1) auto var(--space-3);
  border: 1px solid var(--color-light-3);
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-3);
  width: 304px;
  padding: var(--space-4) 0;
  margin: 0 auto;
  box-sizing: border-box;
}

.form__button {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: calc(var(--space-2) + var(--space-1));
  font-size: var(--fontsize-1);
  border-radius: 3px;
  color: var(--color-light-0);
  background-color: var(--color-primary-1);
  border: none;
  outline: none;
  cursor: pointer;
}

.form__button:hover {
  background-color: var(--color-primary-2);
}

.form__forget-password {
  display: flex;
  font-size: var(--fontsize-0);
}

.form__divider {
  position: relative;
  width: 100%;
  color: var(--color-dark-0);
  font-size: var(--fontsize-1);
  text-align: center;
}

.form__divider::after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  border-bottom: 1px solid var(--color-light-3);
  width: 100%;
  height: 1px;
  z-index: -1;
}

.form__divider span {
  padding: var(--space-3);
  background-color: var(--color-light-0);
}

.footer {
  width: 600px;
  border: 1px solid var(--color-light-3);
  padding: var(--space-3) var(--space-4);
  margin: 0 auto;
  color: var(--color-dark-0);
  font-size: var(--fontsize-1);
  line-height: 1.5;
  box-sizing: border-box;
}

.footer em {
  font-style: normal;
  color: var(--color-primary-1);
}

.tab {
  display: flex;
  align-items: center;
}

.tab__link {
  flex: 1;
  padding: var(--space-3);
  color: var(--color-dark-0);
  background-color: var(--color-light-2);
  font-size: var(--fontsize-2);
  border: none;
  border-bottom: 1px solid var(--color-light-3);
  outline: none;
  cursor: pointer;
}

.tab__link--active {
  background-color: var(--color-light-0);
  color: var(--color-dark-1);
  border-bottom-color: var(--color-light-0);
}

.agreement {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: var(--space-4);
  font-size: var(--fontsize-1);
  color: var(--color-dark-0);
}

.title {
  padding: 0 var(--space-2);
  margin: 0 0 var(--space-3);
  border-left: 3px solid var(--color-dark-2);
  font-size: var(--fontsize-3);
  font-weight: normal;
}

@media screen and (max-width: 576px) {
  .wrapper {
    padding: var(--space-3);
  }

  .container {
    width: 100%;
    box-sizing: border-box;
  }

  .form {
    width: 100%;
    padding: var(--space-3);
  }

  .footer {
    width: 100%;
  }

  .tab__link {
    padding: var(--space-2);
    font-size: var(--fontsize-1);
  }
}
